<template>
  <div id="services" class="token-sale p-tb c-l black-bg white-sec">
    <div class="container mt-6">
      <div id="about" class="about-section style-2 p-tb">
        <div class="row align-items-center">
          <div class="col-md-8 about-left">
            <div class="sec-title"><h1>{{ getServiceProvider.username }}</h1><h3 class="text-left">{{ getServiceProvider.role }}</h3></div>
            <h5 class="accent-color">{{ getServiceProvider.quote }}</h5>
          </div>
          <div class="col-md-4 about-left">
            <img :src="getServiceProvider.logo" alt="" class="wow rotateIn logo" width="200">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="empty-30" />
            <p class="lead">{{ getServiceProvider.description }}</p>
          </div>
        </div>
      </div>
      <div v-if="getServiceProvider.services.length" class="row align-items-center">
        <div class="col-md-12 about-left">
          <div class="sec-title"><h1>Services</h1></div>
          <div v-for="(service, i) in getServiceProvider.services" :key="i" class="mt-4 mb-4">
            <h3 class="text-white text-left">{{ service.serviceType }}</h3>
            <div class="row align-items-center">
              <div v-for="(image, index) in service.images" :key="index" :class="`p-2 ${service.class}`">
                <div v-if="image.src">
                  <a :href="image.link" target="_blank">
                    <img :src="image.src" alt="" class="wow rotateIn">
                  </a>
                </div>
                <img v-else :src="image" alt="" class="wow rotateIn">
              </div>
              <iframe
                v-if="service.link"
                :src="service.link"
                class="col-md-12 p-4"
                width="560"
                height="500"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import logo from '@/assets/png/black-gold.png';
  import sp from '@/assets/png/sp.png';
  import hf from '@/assets/png/hf.png';
  import kd from '@/assets/png/kd.png';
  import zsu from '@/assets/png/zsu.png';
  import taby from '@/assets/png/taby-cartoon.png';
  import kevin from '@/assets/png/kevin.png';
  import prinxxe from '@/assets/png/prinxxe.png';
  import zazi from '@/assets/png/zazi.png';
  import stephImage1 from '@/assets/services/stephan/1.png';
  import stephImage2 from '@/assets/services/stephan/2.png';
  import stephImage3 from '@/assets/services/stephan/3.png';
  import stephImage4 from '@/assets/services/stephan/4.png';
  import stephLogo1 from '@/assets/services/stephan/logo1.png';
  import stephLogo2 from '@/assets/services/stephan/logo2.png';
  import stephLogo3 from '@/assets/services/stephan/logo3.png';
  import stephLogo4 from '@/assets/services/stephan/logo4.png';
  import stephLogo5 from '@/assets/services/stephan/logo5.png';
  import stephLogo6 from '@/assets/services/stephan/logo6.png';
  import stephLogo7 from '@/assets/services/stephan/logo7.png';
  import tabyImage1 from '@/assets/services/taby/web/1.png';
  import tabyImage2 from '@/assets/services/taby/web/2.png';
  import tabyImage3 from '@/assets/services/taby/web/3.png';
  import tabyImage4 from '@/assets/services/taby/web/4.png';
  import tabyLogo1 from '@/assets/services/taby/logo/1.png';
  import tabyLogo2 from '@/assets/services/taby/logo/2.png';
  import tabyLogo4 from '@/assets/services/taby/logo/4.png';
  import tabyLogo6 from '@/assets/services/taby/logo/6.png';
  import tabyLogo7 from '@/assets/services/taby/logo/7.png';
  import tabyLogo8 from '@/assets/services/taby/logo/8.png';
  import tabyGraphic1 from '@/assets/services/taby/graphic/1.png';
  import tabyGraphic2 from '@/assets/services/taby/graphic/2.png';
  import tabyGraphic3 from '@/assets/services/taby/graphic/3.png';
  import tabyGraphic4 from '@/assets/services/taby/graphic/4.png';
  import zsuBanners1 from '@/assets/services/zsuzsa/banners/1.png';
  import zsuBanners2 from '@/assets/services/zsuzsa/banners/2.png';
  import zsuBanners3 from '@/assets/services/zsuzsa/banners/3.png';
  import zsuBanners4 from '@/assets/services/zsuzsa/banners/4.png';
  import zsuBanners5 from '@/assets/services/zsuzsa/banners/5.png';
  import zsuBanners6 from '@/assets/services/zsuzsa/banners/6.png';
  import zsuBanners7 from '@/assets/services/zsuzsa/banners/7.png';
  import zsuBanners8 from '@/assets/services/zsuzsa/banners/8.png';
  import zsuCards3 from '@/assets/services/zsuzsa/cards/3.png';
  import zsuCards4 from '@/assets/services/zsuzsa/cards/4.png';
  import zsuCards5 from '@/assets/services/zsuzsa/cards/5.png';
  import zsuGIF1 from '@/assets/services/zsuzsa/GIF/1.gif';
  import zsuGIF2 from '@/assets/services/zsuzsa/GIF/2.gif';
  import zsuGIF3 from '@/assets/services/zsuzsa/GIF/3.gif';
  import zsuNFT2 from '@/assets/services/zsuzsa/NFT/2.png';
  import zsuNFT3 from '@/assets/services/zsuzsa/NFT/3.png';
  import zsuNFT4 from '@/assets/services/zsuzsa/NFT/4.png';
  import zsuNFT5 from '@/assets/services/zsuzsa/NFT/5.png';
  import zsuNFT6 from '@/assets/services/zsuzsa/NFT/6.png';
  import zsuNFT7 from '@/assets/services/zsuzsa/NFT/7.png';
  import zsuNFT8 from '@/assets/services/zsuzsa/NFT/8.png';
  import zsuNFT9 from '@/assets/services/zsuzsa/NFT/9.png';
  import zsuNFT10 from '@/assets/services/zsuzsa/NFT/10.png';
  import zsuNFT12 from '@/assets/services/zsuzsa/NFT/12.jpg';
  import zsuNFT14 from '@/assets/services/zsuzsa/NFT/14.jpg';
  import zsuNFT15 from '@/assets/services/zsuzsa/NFT/15.jpg';

  export default Vue.extend({
    name: 'elros-incubator-services',
    data: () => ({
      logo,
      ca: '0x91c6d8B00C1e250963849DffD7B1CBb3342683aF',
      sp,
      hf,
      kd,
      kevin,
      serviceProviders: [
        {
          username: 'TraderKyd',
          logo: kd,
          role: 'Web Developer',
          quote: 'Dream it and make it happen!',
          description: 'Highly skilled web developer with over seven years of experience in the industry. Worked in an agile environment for a prominent cyber security company, delivering robust and secure web solutions. Proficient in developing single page applications and adept at implementing design systems to ensure consistent and visually appealing user experiences.',
          services: [],
        },
        {
          username: 'StepanZe1',
          logo: sp,
          role: 'Designer',
          quote: 'Don\'t get too attached to ideas. They can change, and there are always other options.',
          description: 'Experienced graphic designer with versatile creative skills. Responsible team player adept at modern graphic design. Continuously improving and exploring new ideas for creativity and skill development. Expertise includes logo design, thematic posters, 2D/3D video teasers and posters, stickers, and project visualization.',
          services: [
            {
              serviceType: 'Banners and NFTs',
              images: [stephImage1, stephImage2, stephImage3, stephImage4],
              class: 'col-md-6',
            },
            {
              serviceType: 'Logos',
              images: [
                stephLogo1, stephLogo2, stephLogo3, stephLogo4, stephLogo5, stephLogo6, stephLogo7,
              ],
              class: 'col-md-3',
            },
            {
              serviceType: 'Poster',
              link: 'https://www.youtube.com/embed/BFsIu9paRt0',
              class: 'col-md-12',
            },
            {
              serviceType: 'Video',
              link: 'https://www.youtube.com/embed/0f7exofJKIM',
              class: 'col-md-12',
            },
          ],
        },
        {
          username: 'DesignerQueen',
          logo: zsu,
          role: 'Graphic Designer & Illustrator',
          quote: 'Illustration is the art of adding color to words and giving life to ideas.',
          description: 'DesignerQueen, an accomplished illustrator and graphic designer, introduces herself with a passion for expressing ideas and emotions in a unique and captivating manner. Through her expertise in assembling images, illustrations, and motion graphics, she crafts visually appealing creations. DesignerQueen has collaborated with various teams, demonstrating her ability to generate prolific illustration designs. Her portfolio includes a diverse range of projects such as banners, posters, NFTs, motion graphics, and logos, all skillfully executed with a deep understanding of layout and aesthetic design principles.',
          services: [
            {
              serviceType: 'Banners',
              images: [
                zsuBanners1, zsuBanners2, zsuBanners3,
                zsuBanners4, zsuBanners5, zsuBanners6,
                zsuBanners7, zsuBanners8,
              ],
              class: 'col-md-3',
            },
            {
              serviceType: 'Cards',
              images: [
                zsuCards3,
                zsuCards4, zsuCards5,
              ],
              class: 'col-md-4',
            },
            {
              serviceType: 'Gifs',
              images: [zsuGIF1, zsuGIF2, zsuGIF3],
              class: 'col-md-4',
            },
            {
              serviceType: 'NFTs',
              images: [
                zsuNFT2, zsuNFT3, zsuNFT4, zsuNFT5, zsuNFT6, zsuNFT7, zsuNFT8,
                zsuNFT9, zsuNFT10, zsuNFT12,
                zsuNFT14, zsuNFT15,
              ],
              class: 'col-md-4',
            },
          ],
        },
        {
          username: 'TaBy93',
          logo: taby,
          role: 'Web Designer & Developer | Wix Partner',
          quote: 'See your ideas through my eyes.',
          description: 'I\'m TaBy, Web Designer and Developer. I run my own Digital & Creative Agency. We are a full-service digital and creative agency excelling at graphic design, brand development, web design & development. We help companies and business owners worldwide to develop their brand identity.',
          services: [
            {
              serviceType: 'Web Design & Development (exclusively on Wix Platform)',
              images: [
                { src: tabyImage1, link: 'https://www.bridgetek.co/' },
                { src: tabyImage2, link: 'https://www.swissluxuryservices.com/' },
                { src: tabyImage3, link: 'https://www.swissgeneralservices.com/' },
                { src: tabyImage4, link: 'https://www.capeverdeba.com/' },
              ],
              class: 'col-md-12',
            },
            {
              serviceType: 'Branding & Logo Design',
              images: [
                tabyLogo1, tabyLogo2, tabyLogo4,
                tabyLogo6, tabyLogo7, tabyLogo8,
              ],
              class: 'col-md-4',
            },
            {
              serviceType: 'Graphic Design',
              images: [
                tabyGraphic2, tabyGraphic3,
                tabyGraphic4, tabyGraphic1,
              ],
              class: 'col-md-6',
            },
          ],
        },
        {
          username: 'Xemilou',
          logo: hf,
          role: 'Moderator',
          quote: 'A beautiful heart will bring things into your life that all the money in the world couldn\'t buy.',
          description: 'Actively moderated and managed multiple crypto projects, fostering a positive and constructive environment for discussions, resolving conflicts, and promoting community engagement. Utilized strong communication skills to guide discussions, address concerns, and provide valuable insights based on a deep understanding of crypto projects. Played a pivotal role in building vibrant and inclusive communities within the crypto space, contributing to project growth and success.',
          services: [],
        },
        {
          username: 'prinxxe',
          logo: prinxxe,
          role: 'Moderator',
          quote: 'A beautiful heart will bring things into your life that all the money in the world couldn\'t buy.',
          description: 'Actively moderated and managed multiple crypto projects, fostering a positive and constructive environment for discussions, resolving conflicts, and promoting community engagement. Utilized strong communication skills to guide discussions, address concerns, and provide valuable insights based on a deep understanding of crypto projects. Played a pivotal role in building vibrant and inclusive communities within the crypto space, contributing to project growth and success.',
          services: [],
        },
        {
          username: 'LaunchWellKevin',
          logo: kevin,
          role: 'Content Creator & Communications Expert',
          quote: 'Visions become reality through hard work and cooperation.',
          description: 'I am a certified Finnish entrepreneur (Company ID: 2630250-9).  I take pride in my strong morals and clear understanding of empathy and ethics, which I aim to showcase in all that I do. I am fully committed to providing complete client satisfaction and innovative solutions to all of my clients. My services range from white paper and copy creation to videos, interactive videos and expert communication education and analysis.',
          services: [],
        },
        {
          username: 'ZaZi',
          logo: zazi,
          role: 'Copywriter & Content Creator',
          quote: 'Storytelling is the craft of breathing life into empty words and turning them into exciting experiences',
          description: 'Passionate Copywriter and Content Creator who loves translating crazy ideas into amazing content. Don\'t be fooled by my creative side, I\'ve been crushing the IT world for over 14 years now, effortlessly switching from artsy to super-technical content. Need to make a boring piece of wood exciting? I\'m your go-to person. Let\'s tell amazing, captivating stories together!',
          services: [],
        },
      ],
    }),
    computed: {
      getServiceProvider() {
        const { username } = this.$router.currentRoute.params;
        return this.serviceProviders.find(item => item.username === username);
      },
    },
  });
</script>
<style>
  .lead {
    color: white;
  }
</style>
